export var Space;
(function (Space) {
    Space["S4"] = "S4";
    Space["S8"] = "S8";
    Space["S16"] = "S16";
    Space["S24"] = "S24";
    Space["S32"] = "S32";
    Space["S40"] = "S40";
    Space["S64"] = "S64";
    Space["S80"] = "S80";
})(Space || (Space = {}));
export var space = {
    S4: '4px',
    S8: '8px',
    S16: '16px',
    S24: '24px',
    S32: '32px',
    S40: '40px',
    S64: '64px',
    S80: '80px',
};
