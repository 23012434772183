import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './languages/en.json';
import nl from './languages/nl.json';
import lv from './languages/lv.json';
import lt from './languages/lt.json';
import ru from './languages/ru.json';
import pl from './languages/pl.json';
import nl_BE from './languages/nl_BE.json';
import fr from './languages/fr.json';
export var supportedLanguages = ['en', 'nl', 'lt', 'lv', 'ru', 'pl', 'fr', 'BEnl'];
export var defaultLanguage = supportedLanguages[0];
var resources = {
    en: { translation: en },
    nl: { translation: nl },
    lt: { translation: lt },
    lv: { translation: lv },
    ru: { translation: ru },
    pl: { translation: pl },
    fr: { translation: fr },
    BEnl: { translation: nl_BE },
};
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    detection: {
        order: ['path', 'navigator', 'localStorage'],
    },
    resources: resources,
    fallbackLng: defaultLanguage,
    supportedLngs: supportedLanguages,
    keySeparator: false,
    returnNull: true,
    returnEmptyString: false,
    debug: process.env.NODE_ENV === 'development',
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
    },
});
export function withLanguage(path) {
    return '/' + i18n.language + path;
}
export function isLanguageSupported(language) {
    return supportedLanguages.includes(language);
}
export default i18n;
