import { all, equals, values, omit } from 'ramda';
import { GraphOLErrorCode } from 'app/types';
export var getGraphQLErrorMessage = function (code) {
    switch (code) {
        case GraphOLErrorCode.BAD_CREDENTIALS:
            return 'login.bag_credentials_error';
        case GraphOLErrorCode.ACTION_KEY_INVALID:
            return 'change.token_error';
        case GraphOLErrorCode.LOGIN_IS_NOT_UNIQUE:
            return 'signup.dublicate_user';
        case GraphOLErrorCode.RECAPTCHA_CHECK_FAILED:
            return 'signup.reCAPTCHA_failed_error';
        default:
            return 'global.network_error';
    }
};
export var getFileExtension = function (filename) {
    if (filename === void 0) { filename = ''; }
    return ((filename || '').split('.').pop() || '').toLowerCase();
};
export var scrollTo = function (id) {
    var target = document.getElementById(id);
    return target
        ? target.scrollIntoView({ behavior: 'smooth', block: 'start' })
        : null;
};
export var setRequestError = function (_a, setErrorMessage) {
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
    if (networkError) {
        setErrorMessage('global.network_error');
        return setTimeout(function () { return setErrorMessage(''); }, 5000);
    }
    if (graphQLErrors) {
        var errorCode = graphQLErrors[0].extensions.code;
        setErrorMessage(getGraphQLErrorMessage(errorCode));
        return setTimeout(function () { return setErrorMessage(''); }, 5000);
    }
    return null;
};
export var isAllValuesNull = function (obj) {
    var cleanedObject = omit(['__typename'], obj);
    return all(equals(null))(values(cleanedObject));
};
