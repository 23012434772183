var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useContext, useMemo } from 'react';
import { useCurrentUser } from 'app/auth/auth-providers';
var AuthContext = createContext(null);
AuthContext.displayName = 'AuthContext';
var AuthProvider = function (props) {
    var currentUser = useCurrentUser();
    var value = useMemo(function () { return ({ currentUser: currentUser }); }, [currentUser]);
    return React.createElement(AuthContext.Provider, __assign({ value: value }, props));
};
var useAuthContext = function () {
    var context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};
export { AuthProvider, useAuthContext };
