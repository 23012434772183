var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { colors, theme } from 'app/styles';
import { isTabletOrMobile } from 'app/helpers/media-queries';
export var customStyle = {
    container: function (base, state) { return (__assign(__assign({}, base), { fontSize: state.selectProps.isFixedHeader ? '15px' : '18px', fontWeight: 500, fontFamily: theme.fonts.fontFamily, lineHeight: 1.4 })); },
    control: function (base) { return (__assign(__assign({}, base), { cursor: 'pointer', border: 'none', backgroundColor: 'none', boxShadow: 'none' })); },
    valueContainer: function (base) { return (__assign(__assign({}, base), { padding: '2px 2px', width: '50px', justifyContent: 'center', transition: 'all .4s ease-in-out', ':hover': {
            opacity: 0.7,
            transition: 'all .4s ease-in-out',
        } })); },
    input: function (base) { return (__assign(__assign({}, base), { color: 'transparent' })); },
    singleValue: function (base) { return (__assign(__assign({}, base), { color: isTabletOrMobile() ? colors.text : colors.white })); },
    menu: function (base) { return (__assign(__assign({}, base), { border: theme.borderWidth + " solid " + colors.white, zIndex: 5, borderRadius: theme.radii.small, width: '70px' })); },
    dropdownIndicator: function (base, state) { return (__assign(__assign({}, base), { alignItems: 'center', justifyContent: 'center', padding: 0, svg: {
            fill: isTabletOrMobile() ? colors.text : colors.white,
            width: '15px',
            height: '15px',
        }, transition: 'all .2s ease', transform: state.selectProps.isOpen ? 'rotate(180deg)' : null })); },
    menuList: function (base) { return (__assign(__assign({}, base), { background: colors.white, padding: 0 })); },
    option: function (base, state) { return (__assign(__assign({}, base), { ':active': {
            backgroundColor: 'none',
        }, display: state.isSelected && 'none', backgroundColor: 'none', padding: '8px 16px', cursor: 'pointer', color: colors.text, ':hover': {
            backgroundColor: colors.bgLight,
        } })); },
    indicatorSeparator: function (base) { return (__assign(__assign({}, base), { display: 'none' })); },
};
