export var CompanyTurnoverOptions;
(function (CompanyTurnoverOptions) {
    CompanyTurnoverOptions["LESS_50K_EUR"] = "business.revenue_less_50K";
    CompanyTurnoverOptions["RANGE_50_250K_EUR"] = "business.revenue_less_250K";
    CompanyTurnoverOptions["RANGE_251_500K_EUR"] = "business.revenue_less_500K";
    CompanyTurnoverOptions["RANGE_501_1000K_EUR"] = "business.revenue_less_1M";
    CompanyTurnoverOptions["RANGE_1001_2000K_EUR"] = "business.revenue_less_2M";
    CompanyTurnoverOptions["MORE_2000K_EUR"] = "business.revenue_more_2M";
})(CompanyTurnoverOptions || (CompanyTurnoverOptions = {}));
export var CompanyAgeOptions;
(function (CompanyAgeOptions) {
    CompanyAgeOptions["LESS_SIX_MONTHS"] = "business.age_less_6_months";
    CompanyAgeOptions["LESS_ONE_YEAR"] = "business.age_less_12_months";
    CompanyAgeOptions["LESS_THREE_YEARS"] = "business.age_less_3_years";
    CompanyAgeOptions["MORE_THREE_YEARS"] = "business.age_more_3_years";
})(CompanyAgeOptions || (CompanyAgeOptions = {}));
export var LegalFormOptions;
(function (LegalFormOptions) {
    LegalFormOptions["COMPANY"] = "business.legal_form_company";
    LegalFormOptions["SELF_EMPLOYED"] = "business.legal_form_self_employed";
    LegalFormOptions["PARTNERSHIP"] = "business.legal_form_partnership";
    LegalFormOptions["OTHER"] = "business.legal_form_other";
})(LegalFormOptions || (LegalFormOptions = {}));
export var LegalForm;
(function (LegalForm) {
    LegalForm["COMPANY"] = "COMPANY";
    LegalForm["SELF_EMPLOYED"] = "SELF_EMPLOYED";
    LegalForm["PARTNERSHIP"] = "PARTNERSHIP";
    LegalForm["OTHER"] = "OTHER";
})(LegalForm || (LegalForm = {}));
export var EligibilityStatus;
(function (EligibilityStatus) {
    EligibilityStatus["ELIGIBLE"] = "ELIGIBLE";
    EligibilityStatus["NOT_ELIGIBLE"] = "NOT_ELIGIBLE";
})(EligibilityStatus || (EligibilityStatus = {}));
export var BusinessDetailsFieldsNames;
(function (BusinessDetailsFieldsNames) {
    BusinessDetailsFieldsNames["COMPANY_AGE"] = "companyAge";
    BusinessDetailsFieldsNames["COMPANY_NAME"] = "companyName";
    BusinessDetailsFieldsNames["DUN_ADDRESS"] = "dunAddress";
    BusinessDetailsFieldsNames["REGISTRATION_NR"] = "companyRegistrationCode";
    BusinessDetailsFieldsNames["WEB_PAGE"] = "companyWebPage";
    BusinessDetailsFieldsNames["COUNTRY_CODE"] = "countryCode";
    BusinessDetailsFieldsNames["LEGAL_FORM"] = "legalForm";
    BusinessDetailsFieldsNames["OPERATING_B2B"] = "operatingB2B";
    BusinessDetailsFieldsNames["RECEIVABLES_ARE_PLEDGED"] = "receivablesArePledged";
    BusinessDetailsFieldsNames["TURNOVER"] = "turnover";
    BusinessDetailsFieldsNames["DUN_ID"] = "dunId";
    BusinessDetailsFieldsNames["ANOTHER_FACTORING_COMPANY"] = "hasAnotherFactoringCompany";
})(BusinessDetailsFieldsNames || (BusinessDetailsFieldsNames = {}));
export var ResetPasswordFieldsNames;
(function (ResetPasswordFieldsNames) {
    ResetPasswordFieldsNames["EMAIL"] = "email";
})(ResetPasswordFieldsNames || (ResetPasswordFieldsNames = {}));
export var ChangePasswordFieldsNames;
(function (ChangePasswordFieldsNames) {
    ChangePasswordFieldsNames["NEW_PASSWORD"] = "newPassword";
    ChangePasswordFieldsNames["CONFIRM_PASSWORD"] = "confirmPassword";
})(ChangePasswordFieldsNames || (ChangePasswordFieldsNames = {}));
export var LoginFieldsNames;
(function (LoginFieldsNames) {
    LoginFieldsNames["PASSWORD"] = "password";
    LoginFieldsNames["EMAIL"] = "email";
})(LoginFieldsNames || (LoginFieldsNames = {}));
export var SignUpFieldsNames;
(function (SignUpFieldsNames) {
    SignUpFieldsNames["FIRST_NAME"] = "firstName";
    SignUpFieldsNames["LAST_NAME"] = "lastName";
    SignUpFieldsNames["PHONE"] = "phone";
    SignUpFieldsNames["PASSWORD"] = "password";
    SignUpFieldsNames["CONFIRM_PASSWORD"] = "confirmPassword";
    SignUpFieldsNames["EMAIL"] = "email";
    SignUpFieldsNames["RECAPTCHA_TOKEN"] = "recaptchaToken";
})(SignUpFieldsNames || (SignUpFieldsNames = {}));
export var ClientStatus;
(function (ClientStatus) {
    ClientStatus["APPROVED"] = "APPROVED";
    ClientStatus["BLOCKED"] = "BLOCKED";
    ClientStatus["BLOCKED_EMAIL_CONFIRMED"] = "BLOCKED_EMAIL_CONFIRMED";
    ClientStatus["BLOCKED_INITIATED"] = "BLOCKED_INITIATED";
    ClientStatus["EMAIL_CONFIRMED"] = "EMAIL_CONFIRMED";
    ClientStatus["REGISTRATION_INITIATED"] = "REGISTRATION_INITIATED";
})(ClientStatus || (ClientStatus = {}));
export var VisitedFormStatus;
(function (VisitedFormStatus) {
    VisitedFormStatus["LAST_VISITED"] = "LAST_VISITED";
    VisitedFormStatus["NOT_VISITED"] = "NOT_VISITED";
    VisitedFormStatus["VISITED"] = "VISITED";
})(VisitedFormStatus || (VisitedFormStatus = {}));
export var FinancialReviewDocumentName;
(function (FinancialReviewDocumentName) {
    FinancialReviewDocumentName["ARTICLE_OF_ASSOCIATION"] = "articleOfAssociation";
    FinancialReviewDocumentName["BALANCE_SHEET"] = "balanceSheet";
    FinancialReviewDocumentName["BANK_STATEMENT"] = "bankStatement";
    FinancialReviewDocumentName["CERTIFICATE_OF_INCORPORATION"] = "certificateOfIncorporation";
    FinancialReviewDocumentName["DEBTORS_OVERVIEW"] = "debtorsOverview";
    FinancialReviewDocumentName["PROFIT_LOSS_REPORT"] = "profitLossReport";
})(FinancialReviewDocumentName || (FinancialReviewDocumentName = {}));
export var FinancialReviewDocumentType;
(function (FinancialReviewDocumentType) {
    FinancialReviewDocumentType["ARTICLE_OF_ASSOCIATION"] = "ARTICLE_OF_ASSOCIATION";
    FinancialReviewDocumentType["BALANCE_SHEET"] = "BALANCE_SHEET";
    FinancialReviewDocumentType["BANK_STATEMENT"] = "BANK_STATEMENT";
    FinancialReviewDocumentType["CERTIFICATE_OF_INCORPORATION"] = "CERTIFICATE_OF_INCORPORATION";
    FinancialReviewDocumentType["DEBTORS_OVERVIEW"] = "DEBTORS_OVERVIEW";
    FinancialReviewDocumentType["PROFIT_LOSS_REPORT"] = "PROFIT_LOSS_REPORT";
})(FinancialReviewDocumentType || (FinancialReviewDocumentType = {}));
export var StakeholdersFieldsNames;
(function (StakeholdersFieldsNames) {
    StakeholdersFieldsNames["NAME"] = "legalRepresentativeName";
    StakeholdersFieldsNames["SURNAME"] = "legalRepresentativeSurname";
    StakeholdersFieldsNames["INVOLVED_IN_BANKRUPTCY"] = "legalRepresentativeInvolvedInBankruptcy";
    StakeholdersFieldsNames["DOB"] = "legalRepresentativeBirthDate";
    StakeholdersFieldsNames["HAS_BENEFICIAL_OWNERS"] = "hasBeneficialOwners";
    StakeholdersFieldsNames["NATIONALITY"] = "legalRepresentativeNationality";
})(StakeholdersFieldsNames || (StakeholdersFieldsNames = {}));
export var YesNo;
(function (YesNo) {
    YesNo["YES"] = "YES";
    YesNo["NO"] = "NO";
})(YesNo || (YesNo = {}));
export var StakeholderDocumentName;
(function (StakeholderDocumentName) {
    StakeholderDocumentName["LEGAL_REPRESENTATIVE_ID"] = "legalRepresentativeIdDocument";
    StakeholderDocumentName["UBO_ID_DOCUMENTS"] = "uboIdDocument";
    StakeholderDocumentName["UBO_SIGNED_STRUCTURE"] = "uboSignedStructure";
})(StakeholderDocumentName || (StakeholderDocumentName = {}));
export var StakeholderDocumentType;
(function (StakeholderDocumentType) {
    StakeholderDocumentType["LEGAL_REPRESENTATIVE_ID"] = "LEGAL_REPRESENTATIVE_ID";
    StakeholderDocumentType["UBO_ID_DOCUMENTS"] = "UBO_ID_DOCUMENTS";
    StakeholderDocumentType["UBO_SIGNED_STRUCTURE"] = "UBO_SIGNED_STRUCTURE";
})(StakeholderDocumentType || (StakeholderDocumentType = {}));
export var BusinessRegistrationFieldsNames;
(function (BusinessRegistrationFieldsNames) {
    BusinessRegistrationFieldsNames["COMPANY_NAME"] = "companyName";
    BusinessRegistrationFieldsNames["REGISTRATION_NR"] = "companyRegistrationCode";
    BusinessRegistrationFieldsNames["COUNTRY_CODE"] = "countryCode";
    BusinessRegistrationFieldsNames["DUN_ID"] = "dunId";
    BusinessRegistrationFieldsNames["DUN_NAME"] = "dunName";
    BusinessRegistrationFieldsNames["DUN_ADDRESS"] = "dunAddress";
    BusinessRegistrationFieldsNames["SHOW_MANUAL_SEARCH"] = "showManualSearch";
})(BusinessRegistrationFieldsNames || (BusinessRegistrationFieldsNames = {}));
export var DebtorsFieldsNames;
(function (DebtorsFieldsNames) {
    DebtorsFieldsNames["COMPANY_NAME"] = "companyName";
    DebtorsFieldsNames["REGISTRATION_NR"] = "companyRegistrationCode";
    DebtorsFieldsNames["COUNTRY_CODE"] = "countryCode";
    DebtorsFieldsNames["DUN_ID"] = "dunId";
    DebtorsFieldsNames["DUN_NAME"] = "dunName";
    DebtorsFieldsNames["EXPECTED_FACTORING_VOLUME"] = "expectedFactoringVolume";
    DebtorsFieldsNames["PAYMENT_TERM"] = "paymentTerm";
    DebtorsFieldsNames["INVOICE_TO_FUND"] = "invoicesToFund";
    DebtorsFieldsNames["MASTER_AGREEMENT"] = "masterAgreement";
    DebtorsFieldsNames["TRADING_NOT_WITH_EURO"] = "tradingNotWithEuro";
    DebtorsFieldsNames["SHOW_MANUAL_SEARCH"] = "showManualSearch";
})(DebtorsFieldsNames || (DebtorsFieldsNames = {}));
export var DebtorDocumentType;
(function (DebtorDocumentType) {
    DebtorDocumentType["INVOICE_TO_FUND"] = "INVOICE_TO_FUND";
    DebtorDocumentType["MASTER_AGREEMENT"] = "MASTER_AGREEMENT";
})(DebtorDocumentType || (DebtorDocumentType = {}));
export var GraphOLErrorCode;
(function (GraphOLErrorCode) {
    GraphOLErrorCode["BAD_CREDENTIALS"] = "BAD_CREDENTIALS";
    GraphOLErrorCode["ACTION_KEY_INVALID"] = "ACTION_KEY_INVALID";
    GraphOLErrorCode["LOGIN_IS_NOT_UNIQUE"] = "LOGIN_IS_NOT_UNIQUE";
    GraphOLErrorCode["RECAPTCHA_CHECK_FAILED"] = "RECAPTCHA_CHECK_FAILED";
})(GraphOLErrorCode || (GraphOLErrorCode = {}));
