export var Breakpoints;
(function (Breakpoints) {
    Breakpoints["SM"] = "sm";
    Breakpoints["MD"] = "md";
    Breakpoints["LG"] = "lg";
    Breakpoints["XL"] = "xl";
})(Breakpoints || (Breakpoints = {}));
export var breakPoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1220
};
export var breakPointsPx = {
    sm: breakPoints.sm + "px",
    md: breakPoints.md + "px",
    lg: breakPoints.lg + "px",
    xl: breakPoints.xl + "px"
};
