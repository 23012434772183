import { makeVar, InMemoryCache } from '@apollo/client';
export var isAuthenticatedVar = makeVar(false);
export var cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isAuthenticatedVar: {
                    read: function () {
                        return isAuthenticatedVar();
                    }
                },
            }
        }
    }
});
