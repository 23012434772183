import React from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'app/translations';
import { Select } from 'app/atoms/select';
import { LanguageList } from 'app/types/countries';
import { customStyle } from './custom-language-selector-style';
var options = Object.keys(LanguageList)
    .map(function (key) { return ({ label: key, value: LanguageList[key] }); });
var defaultOption = options.filter(function (option) { return option.value === i18n.language; });
export var LanguageSelector = function (_a) {
    var isFixedHeader = _a.isFixedHeader;
    var pathname = useLocation().pathname;
    var changeLanguage = function (option) {
        var nextLang = option.value;
        var path = pathname.split('/').slice(2).join('/');
        var newUrl = "/" + nextLang + "/" + path;
        window.history.replaceState(null, '', newUrl);
        i18n.changeLanguage(nextLang);
    };
    return (React.createElement(Select, { defaultValue: defaultOption, options: options, onChange: changeLanguage, customStyle: customStyle, openMenuOnFocus: true, hideSelectedOptions: true, isFixedHeader: isFixedHeader }));
};
