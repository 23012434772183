var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { BusinessDetailsAnswers, ClientDebtors, FileInfo, StakeholdersAnswers, StakeholdersDocuments, } from './fragments';
export var GET_CURRENT_ACCOUNT_INFO = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetCurrentAccountInfo {\n    currentAccount {\n      status\n      email\n      individual\n      onboardingStarted\n      submitted\n      companyCountryCode\n      dealId\n      visited {\n        businessDetailsPage\n        invoicePage\n        financialReviewPage\n        stakeholdersPage\n      }\n    }\n  }\n"], ["\n  query GetCurrentAccountInfo {\n    currentAccount {\n      status\n      email\n      individual\n      onboardingStarted\n      submitted\n      companyCountryCode\n      dealId\n      visited {\n        businessDetailsPage\n        invoicePage\n        financialReviewPage\n        stakeholdersPage\n      }\n    }\n  }\n"])));
export var GET_BUSINESS_DETAILS_ANSWERS = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query GetBusinessDetailsAnswers {\n    clientsAnswers {\n      ...BusinessDetailsAnswers\n    }\n  }\n  ", "\n"], ["\n  query GetBusinessDetailsAnswers {\n    clientsAnswers {\n      ...BusinessDetailsAnswers\n    }\n  }\n  ", "\n"])), BusinessDetailsAnswers);
export var GET_BUSINESS_DETAILS_ANSWERS_SUMMARY = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query GetBusinessDetailsAnswersSummary {\n    clientsAnswersSummary {\n      ...BusinessDetailsAnswers\n    }\n  }\n  ", "\n"], ["\n  query GetBusinessDetailsAnswersSummary {\n    clientsAnswersSummary {\n      ...BusinessDetailsAnswers\n    }\n  }\n  ", "\n"])), BusinessDetailsAnswers);
export var SEARCH_DUNS_COMPANIES = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query SearchDunsCompanies($searchTerm: String!,$countryCode: String) {\n    searchDunsCompanies(searchTerm: $searchTerm, countryCode: $countryCode ) {\n      dunId\n      name\n      address\n      searchTerm\n    }\n  }\n"], ["\n  query SearchDunsCompanies($searchTerm: String!,$countryCode: String) {\n    searchDunsCompanies(searchTerm: $searchTerm, countryCode: $countryCode ) {\n      dunId\n      name\n      address\n      searchTerm\n    }\n  }\n"])));
export var GET_FINANCIAL_REVIEW_DOCUMENTS = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  query FinancialReviewDocuments {\n    financialReviewDocuments {\n      articleOfAssociation {\n        ...FileInfo\n      }\n      balanceSheet {\n        ...FileInfo\n      }\n      bankStatement {\n        ...FileInfo\n      }\n      certificateOfIncorporation {\n        ...FileInfo\n      }\n      debtorsOverview {\n        ...FileInfo\n      }\n      profitLossReport {\n        ...FileInfo\n      }\n    }\n  }\n  ", "\n"], ["\n  query FinancialReviewDocuments {\n    financialReviewDocuments {\n      articleOfAssociation {\n        ...FileInfo\n      }\n      balanceSheet {\n        ...FileInfo\n      }\n      bankStatement {\n        ...FileInfo\n      }\n      certificateOfIncorporation {\n        ...FileInfo\n      }\n      debtorsOverview {\n        ...FileInfo\n      }\n      profitLossReport {\n        ...FileInfo\n      }\n    }\n  }\n  ", "\n"])), FileInfo);
export var GET_FINANCIAL_REVIEW_DOCUMENTS_SUMMARY = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  query FinancialReviewDocumentsSummary {\n    financialReviewDocumentsSummary {\n      articleOfAssociation {\n        ...FileInfo\n      }\n      balanceSheet {\n        ...FileInfo\n      }\n      bankStatement {\n        ...FileInfo\n      }\n      certificateOfIncorporation {\n        ...FileInfo\n      }\n      debtorsOverview {\n        ...FileInfo\n      }\n      profitLossReport {\n        ...FileInfo\n      }\n    }\n  }\n  ", "\n"], ["\n  query FinancialReviewDocumentsSummary {\n    financialReviewDocumentsSummary {\n      articleOfAssociation {\n        ...FileInfo\n      }\n      balanceSheet {\n        ...FileInfo\n      }\n      bankStatement {\n        ...FileInfo\n      }\n      certificateOfIncorporation {\n        ...FileInfo\n      }\n      debtorsOverview {\n        ...FileInfo\n      }\n      profitLossReport {\n        ...FileInfo\n      }\n    }\n  }\n  ", "\n"])), FileInfo);
export var DOWNLOAD_DOCUMENT = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  query DownloadDocument ($documentId: UUID!) {\n    downloadDocument (documentId: $documentId) {\n      content\n      fileName\n    }\n  }\n"], ["\n  query DownloadDocument ($documentId: UUID!) {\n    downloadDocument (documentId: $documentId) {\n      content\n      fileName\n    }\n  }\n"])));
export var GET_STAKEHOLDERS_SUMMARY = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query StakeholdersSummary {\n    stakeholdersSummary {\n      ...StakeholdersAnswers\n      ...StakeholdersDocuments\n    }\n  }\n  ", "\n  ", "\n"], ["\n  query StakeholdersSummary {\n    stakeholdersSummary {\n      ...StakeholdersAnswers\n      ...StakeholdersDocuments\n    }\n  }\n  ", "\n  ", "\n"])), StakeholdersDocuments, StakeholdersAnswers);
export var GET_STAKEHOLDERS_ANSWERS = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  query Stakeholders {\n    stakeholders {\n      ...StakeholdersAnswers\n    }\n  }\n  ", "\n"], ["\n  query Stakeholders {\n    stakeholders {\n      ...StakeholdersAnswers\n    }\n  }\n  ", "\n"])), StakeholdersAnswers);
export var GET_STAKEHOLDERS_DOCUMENTS = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  query Stakeholders {\n    stakeholders {\n      ...StakeholdersDocuments\n    }\n  }\n  ", "\n"], ["\n  query Stakeholders {\n    stakeholders {\n      ...StakeholdersDocuments\n    }\n  }\n  ", "\n"])), StakeholdersDocuments);
export var GET_CLIENT_DEBTORS = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  query GetClientDebtors {\n    clientDebtors {\n      ...ClientDebtors\n    }\n  }\n  ", "\n"], ["\n  query GetClientDebtors {\n    clientDebtors {\n      ...ClientDebtors\n    }\n  }\n  ", "\n"])), ClientDebtors);
export var GET_CLIENT_DEBTORS_SUMMARY = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  query GetClientDebtorsSummary {\n    clientDebtorsSummary {\n      ...ClientDebtors\n    }\n  }\n  ", "\n"], ["\n  query GetClientDebtorsSummary {\n    clientDebtorsSummary {\n      ...ClientDebtors\n    }\n  }\n  ", "\n"])), ClientDebtors);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
