import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Redirect, Switch, useHistory, Route } from 'react-router-dom';
import apolloClient from 'app/apollo/apolloClient';
import { AppLayout } from 'app/components/app-layout';
import { Spinner } from 'app/atoms/spinner';
import { PrivateRoute } from 'app/auth';
import { path } from 'app/navigation';
import { AuthProvider } from 'app/context/auth-context';
var LoginPage = React.lazy(function () { return import('app/pages/login'); });
var SignUpPage = React.lazy(function () { return import('app/pages/sign-up'); });
var ConfirmEmail = React.lazy(function () { return import('app/pages/confirm-email'); });
var BusinessDetailsPage = React.lazy(function () { return import('app/pages/business-details'); });
var FinancialReviewPage = React.lazy(function () { return import('app/pages/financial-review'); });
var StakeholdersPage = React.lazy(function () { return import('app/pages/stakeholders'); });
var invoicePage = React.lazy(function () { return import('app/pages/your-customers'); });
var StartPage = React.lazy(function () { return import('app/pages/start'); });
var ResetPasswordPage = React.lazy(function () { return import('app/pages/reset-password'); });
var ChangePasswordPage = React.lazy(function () { return import('app/pages/change-password'); });
var App = function () {
    var history = useHistory();
    return (React.createElement(ApolloProvider, { client: apolloClient(history) },
        React.createElement(AuthProvider, null,
            React.createElement(AppLayout, null,
                React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: path.loginPage, exact: true, component: LoginPage }),
                        React.createElement(Route, { path: path.resetPassword, exact: true, component: ResetPasswordPage }),
                        React.createElement(Route, { path: path.changePassword, exact: true, component: ChangePasswordPage }),
                        React.createElement(Route, { path: path.signupPage, exact: true, component: SignUpPage }),
                        React.createElement(Route, { path: path.confirmEmail, exact: true, component: ConfirmEmail }),
                        React.createElement(PrivateRoute, { path: path.startPage, component: StartPage }),
                        React.createElement(PrivateRoute, { path: path.businessDetailsPage, exact: true, component: BusinessDetailsPage }),
                        React.createElement(PrivateRoute, { path: path.financialReviewPage, restrictedContent: true, exact: true, component: FinancialReviewPage }),
                        React.createElement(PrivateRoute, { path: path.stakeholdersPage, restrictedContent: true, exact: true, component: StakeholdersPage }),
                        React.createElement(PrivateRoute, { path: path.invoicePage, restrictedContent: true, exact: true, component: invoicePage }),
                        React.createElement(Redirect, { to: path.startPage })))))));
};
export default App;
