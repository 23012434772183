var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Select, { components } from 'react-select';
import { getCustomStyle } from './custom-select-style';
var SelectContainer = function (props) {
    return (React.createElement(components.SelectContainer, __assign({}, props, { innerProps: __assign(__assign({}, props.innerProps), { 'data-test': props.selectProps.dataTest }) })));
};
var SelectComponent = function (_a) {
    var options = _a.options, value = _a.value, name = _a.name, _b = _a.isClearable, isClearable = _b === void 0 ? false : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, _e = _a.isSearchable, isSearchable = _e === void 0 ? true : _e, placeholder = _a.placeholder, hasError = _a.hasError, onChange = _a.onChange, onMenuOpen = _a.onMenuOpen, onFocus = _a.onFocus, onInputChange = _a.onInputChange, customStyle = _a.customStyle, dataTest = _a.dataTest, components = _a.components, rest = __rest(_a, ["options", "value", "name", "isClearable", "isDisabled", "isLoading", "isSearchable", "placeholder", "hasError", "onChange", "onMenuOpen", "onFocus", "onInputChange", "customStyle", "dataTest", "components"]);
    return (React.createElement(Select, __assign({ value: value, onChange: onChange, options: options, name: name, isSearchable: isSearchable, isClearable: isClearable, isDisabled: isDisabled, isLoading: isLoading, placeholder: placeholder, onInputChange: onInputChange, onMenuOpen: onMenuOpen, onFocus: onFocus, styles: customStyle || getCustomStyle({ hasError: hasError, isClearable: isClearable }), classNamePrefix: "react-select", components: __assign({ SelectContainer: SelectContainer }, components), dataTest: dataTest }, rest)));
};
SelectComponent.displayName = 'Select';
export default SelectComponent;
