import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakPointsPx, breakPoints } from 'app/styles/breakpoints';
export var MediaQueries;
(function (MediaQueries) {
    MediaQueries["MOBILE"] = "mobile";
    MediaQueries["NOT_MOBILE"] = "notMobile";
    MediaQueries["TABLET"] = "tablet";
    MediaQueries["TABLET_OR_MOBILE"] = "tabletOrMobile";
    MediaQueries["NOT_DESKTOP"] = "notDesktop";
    MediaQueries["DESKTOP"] = "desktop";
})(MediaQueries || (MediaQueries = {}));
export var mediaQueries = {
    mobile: "@media only screen and (max-width: " + (breakPoints.md - 1) + "px)",
    notMobile: "@media only screen and (min-width: " + breakPointsPx.md + ")",
    tablet: "@media only screen and (min-width: " + breakPointsPx.md + ") and (max-width: " + (breakPoints.lg - 1) + "px)",
    tabletOrMobile: "@media only screen and (max-width: " + (breakPoints.lg - 1) + "px)",
    notDesktop: "@media only screen and (max-width: " + (breakPoints.xl - 1) + "px)",
    desktop: "@media only screen and (min-width: " + breakPointsPx.xl + ")",
};
export var isMobile = function () { return useMediaQuery({ maxWidth: breakPoints.md - 1 }); };
export var isNotMobile = function () { return useMediaQuery({ minWidth: breakPoints.md }); };
export var isTablet = function () { return useMediaQuery({ minWidth: breakPoints.md, maxWidth: breakPoints.lg - 1 }); };
export var isTabletOrMobile = function () { return useMediaQuery({ maxWidth: breakPoints.lg - 1 }); };
export var isDesktop = function () { return useMediaQuery({ minWidth: breakPoints.xl }); };
export var isLaptopOrDesktop = function () { return useMediaQuery({ minWidth: breakPoints.lg }); };
export var Desktop = function (_a) {
    var children = _a.children;
    return isDesktop() ? React.createElement(Fragment, null, children) : null;
};
export var LaptopOrDesktop = function (_a) {
    var children = _a.children;
    return isLaptopOrDesktop() ? React.createElement(Fragment, null, children) : null;
};
export var TabletOrMobile = function (_a) {
    var children = _a.children;
    return isTabletOrMobile() ? React.createElement(Fragment, null, children) : null;
};
export var Tablet = function (_a) {
    var children = _a.children;
    return isTablet() ? React.createElement(Fragment, null, children) : null;
};
export var Mobile = function (_a) {
    var children = _a.children;
    return isMobile() ? React.createElement(Fragment, null, children) : null;
};
export var Default = function (_a) {
    var children = _a.children;
    return isNotMobile() ? React.createElement(Fragment, null, children) : null;
};
