var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Header } from 'app/components/header';
import { useBackgroundColor } from 'app/hooks/use-background-color';
var AppLayout = function (_a) {
    var children = _a.children;
    var _b = useState(false), isFixed = _b[0], setFixed = _b[1];
    var color = useBackgroundColor();
    var ref = useRef(null);
    var handleScroll = function () {
        if (ref.current) {
            setFixed(ref.current.getBoundingClientRect().top < 0);
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () {
            window.removeEventListener('scroll', function () { return handleScroll; });
        };
    }, []);
    return (React.createElement(AppWrapper, { color: color },
        React.createElement(Header, { isFixed: isFixed }),
        React.createElement(ContentWrapper, { ref: ref, color: color }, children)));
};
var AppWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  align-items: center;\n  background: ", ";\n\n  ", " {\n    background: ", ";\n  }\n  ", " {\n    padding: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  align-items: center;\n  background: ",
    ";\n\n  ", " {\n    background: ", ";\n  }\n  ", " {\n    padding: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, color = _a.color;
    return "linear-gradient(to right, " + theme.colors.white + " 50%, " + theme.colors[color] + " 50%)";
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaQueries.tabletOrMobile;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaQueries.notDesktop;
}, function (_a) {
    var theme = _a.theme;
    return "0 " + theme.space.S24;
});
var ContentWrapper = styled.main(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  max-width: 1220px;\n  min-width: 300px;\n  width: 100%;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n\n  ", " {\n    background: ", ";\n  }\n"], ["\n  position: relative;\n  max-width: 1220px;\n  min-width: 300px;\n  width: 100%;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  background: ",
    ";\n\n  ", " {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, color = _a.color;
    return "linear-gradient(to right, " + theme.colors.white + " 60%, " + theme.colors[color] + " 60%)";
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaQueries.tabletOrMobile;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
});
export default AppLayout;
var templateObject_1, templateObject_2;
