var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { onError } from '@apollo/client/link/error';
import { ApolloClient, createHttpLink, ApolloLink, fromPromise, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { UPDATE_TOKENS, LOGOUT } from 'app/api/mutations';
import i18n from 'app/translations';
import * as storageUtils from 'app/helpers/storage-utils';
import { path } from 'app/navigation';
import { isProduction, isTest, prodGraphqlUrl, devGraphqlUrl, testGraphqlUrl } from 'app/helpers/config';
import { cache, isAuthenticatedVar } from './cache';
console.log('isProduction', isProduction);
console.log('devGraphqlUrl', devGraphqlUrl);
var refreshAuthToken = function () { return apolloClient().mutate({
    mutation: UPDATE_TOKENS,
}); };
var logout = function () { return apolloClient().mutate({
    mutation: LOGOUT,
}); };
var httpLink = createHttpLink({
    uri: isProduction
        ? prodGraphqlUrl
        : isTest ? testGraphqlUrl : devGraphqlUrl,
    credentials: 'include',
});
var authLink = setContext(function (_, _a) {
    var headers = _a.headers;
    return ({
        headers: __assign(__assign({}, headers), { 'Accept-Language': i18n.language })
    });
});
var errorLink = function (history) { return onError(function (_a) {
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation, forward = _a.forward;
    if (graphQLErrors) {
        if (graphQLErrors[0].extensions.code === 'UNAUTHORISED') {
            return fromPromise(refreshAuthToken()
                .then(function () { return isAuthenticatedVar(true); })
                .catch(function () { return __awaiter(void 0, void 0, void 0, function () {
                var isAuth;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            isAuth = isAuthenticatedVar();
                            if (!isAuth) return [3, 2];
                            return [4, logout()];
                        case 1:
                            _a.sent();
                            storageUtils.clearLocalStorage();
                            isAuthenticatedVar(false);
                            history.push(path.loginPage);
                            _a.label = 2;
                        case 2: return [2];
                    }
                });
            }); })).flatMap(function () { return forward(operation); });
        }
        return graphQLErrors.forEach(function (_a) {
            var message = _a.message, locations = _a.locations, errorPath = _a.path;
            return console.log("[GraphQL error]: Message: " + message + ", Location: " + locations + ", Path: " + errorPath);
        });
    }
    if (networkError) {
        console.log("[Network error]: " + networkError);
    }
}); };
var apolloClient = function (history) { return new ApolloClient({
    link: ApolloLink.from([errorLink(history), authLink.concat(httpLink)]),
    cache: cache,
    defaultOptions: {
        watchQuery: { fetchPolicy: 'network-only' }
    }
}); };
export default apolloClient;
