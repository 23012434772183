var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var BusinessDetailsAnswers = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment BusinessDetailsAnswers on OnboardInfoDTO {\n    companyAge\n    companyName\n    companyRegistrationCode\n    companyWebPage\n    countryCode\n    legalForm\n    operatingB2B\n    receivablesArePledged\n    turnover\n    dunAddress\n    dunId\n    dunName\n    eligibilityStatus\n    hasAnotherFactoringCompany\n  }\n"], ["\n  fragment BusinessDetailsAnswers on OnboardInfoDTO {\n    companyAge\n    companyName\n    companyRegistrationCode\n    companyWebPage\n    countryCode\n    legalForm\n    operatingB2B\n    receivablesArePledged\n    turnover\n    dunAddress\n    dunId\n    dunName\n    eligibilityStatus\n    hasAnotherFactoringCompany\n  }\n"])));
export var AuthenticationInfo = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fragment AuthenticationInfo on AuthenticationInfoDTO {\n    login\n    dealId\n  }\n"], ["\n  fragment AuthenticationInfo on AuthenticationInfoDTO {\n    login\n    dealId\n  }\n"])));
export var RegisteredClientAuthInfo = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fragment RegisteredClientAuthInfo on RegisteredClientDTO {\n    authenticationInfo {\n      ...AuthenticationInfo\n    }\n  }\n  ", "\n"], ["\n  fragment RegisteredClientAuthInfo on RegisteredClientDTO {\n    authenticationInfo {\n      ...AuthenticationInfo\n    }\n  }\n  ", "\n"])), AuthenticationInfo);
export var FileInfo = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  fragment FileInfo on CompanyFileDTO {\n    content\n    fileName\n    id\n    size\n    documentType\n  }\n"], ["\n  fragment FileInfo on CompanyFileDTO {\n    content\n    fileName\n    id\n    size\n    documentType\n  }\n"])));
export var StakeholdersInfo = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  fragment StakeholdersInfo on StakeholdersDTO {\n    hasBeneficialOwners\n    legalRepresentativeBirthDate\n    legalRepresentativeInvolvedInBankruptcy\n    legalRepresentativeName\n    legalRepresentativeSurname\n    legalRepresentativeNationality\n  }\n"], ["\n  fragment StakeholdersInfo on StakeholdersDTO {\n    hasBeneficialOwners\n    legalRepresentativeBirthDate\n    legalRepresentativeInvolvedInBankruptcy\n    legalRepresentativeName\n    legalRepresentativeSurname\n    legalRepresentativeNationality\n  }\n"])));
export var StakeholdersDocuments = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  fragment StakeholdersDocuments on StakeholdersDTO {\n    legalRepresentativeIdDocument {\n      ...FileInfo\n    }\n    uboIdDocument {\n      ...FileInfo\n    }\n    uboSignedStructure {\n      ...FileInfo\n    }\n  }\n  ", "\n"], ["\n  fragment StakeholdersDocuments on StakeholdersDTO {\n    legalRepresentativeIdDocument {\n      ...FileInfo\n    }\n    uboIdDocument {\n      ...FileInfo\n    }\n    uboSignedStructure {\n      ...FileInfo\n    }\n  }\n  ", "\n"])), FileInfo);
export var StakeholdersAnswers = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  fragment StakeholdersAnswers on StakeholdersDTO {\n    hasBeneficialOwners\n    legalRepresentativeBirthDate\n    legalRepresentativeInvolvedInBankruptcy\n    legalRepresentativeName\n    legalRepresentativeSurname\n    legalRepresentativeNationality\n  }\n"], ["\n  fragment StakeholdersAnswers on StakeholdersDTO {\n    hasBeneficialOwners\n    legalRepresentativeBirthDate\n    legalRepresentativeInvolvedInBankruptcy\n    legalRepresentativeName\n    legalRepresentativeSurname\n    legalRepresentativeNationality\n  }\n"])));
export var ClientDebtors = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  fragment ClientDebtors on DebtorInfoDTO {\n    countryCode\n    companyName\n    companyRegistrationCode\n    dunId\n    dunName\n    dunAddress\n    expectedFactoringVolume\n    id\n    tradingNotWithEuro\n    invoicesToFund {\n      ...FileInfo\n    }\n    masterAgreement {\n      ...FileInfo\n    }\n    paymentTerm\n  }\n  ", "\n"], ["\n  fragment ClientDebtors on DebtorInfoDTO {\n    countryCode\n    companyName\n    companyRegistrationCode\n    dunId\n    dunName\n    dunAddress\n    expectedFactoringVolume\n    id\n    tradingNotWithEuro\n    invoicesToFund {\n      ...FileInfo\n    }\n    masterAgreement {\n      ...FileInfo\n    }\n    paymentTerm\n  }\n  ", "\n"])), FileInfo);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
