var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { TabletOrMobile, LaptopOrDesktop } from 'app/helpers/media-queries';
import { useBackgroundColor } from 'app/hooks/use-background-color';
import MobileHeaderContent from './mobile-header-content';
import DesktopHeaderContent from './desktop-header-content';
var Header = function (_a) {
    var isFixed = _a.isFixed;
    var color = useBackgroundColor();
    return (React.createElement(HeaderWrapper, { isFixed: isFixed, color: color },
        React.createElement(HeaderContent, { isFixed: isFixed, color: color },
            React.createElement(TabletOrMobile, null,
                React.createElement(MobileHeaderContent, null)),
            React.createElement(LaptopOrDesktop, null,
                React.createElement(DesktopHeaderContent, { isFixedHeader: isFixed })))));
};
var HeaderWrapper = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  box-sizing: border-box;\n  top: 0;\n  z-index: 100;\n  background: ", ";\n\n  ", " {\n    padding: ", ";\n  }\n\n  ", " {\n    background: ", ";\n  }\n"], ["\n  position: fixed;\n  width: 100%;\n  box-sizing: border-box;\n  top: 0;\n  z-index: 100;\n  background: ", ";\n\n  ", " {\n    padding: ", ";\n  }\n\n  ", " {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, color = _a.color;
    return "linear-gradient(to right, " + theme.colors.white + " 50%, " + theme.colors[color] + " 50%)";
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaQueries.notDesktop;
}, function (_a) {
    var theme = _a.theme;
    return "0 " + theme.space.S24;
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaQueries.tabletOrMobile;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
});
var HeaderContent = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 auto;\n  transition: 0.3s all ease;\n  padding-top: ", ";\n  background: ", ";\n  box-sizing: border-box;\n  max-width: 1220px;\n  min-width: 300px;\n\n", " {\n    padding-top: 0;\n    background: ", ";\n  }\n"], ["\n  margin: 0 auto;\n  transition: 0.3s all ease;\n  padding-top: ", ";\n  background: ", ";\n  box-sizing: border-box;\n  max-width: 1220px;\n  min-width: 300px;\n\n", " {\n    padding-top: 0;\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, isFixed = _a.isFixed;
    return isFixed ? 0 : theme.space.S16;
}, function (_a) {
    var theme = _a.theme, color = _a.color;
    return "linear-gradient(to right, " + theme.colors.white + " 60%, " + theme.colors[color] + " 60%)";
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaQueries.tabletOrMobile;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
});
export default Header;
var templateObject_1, templateObject_2;
