var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var StyledHamburgerMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  position: relative;\n  height: 20px;\n  width: 20px;\n  z-index: 101;\n\n  span {\n    display: block;\n    position: absolute;\n    height: 2px;\n    width: 100%;\n    background: ", ";\n    border-radius: 9px;\n    opacity: 1;\n    left: 0;\n    transform: rotate(0deg);\n    transition: .25s ease-in-out;\n\n    &:first-child {\n      top: 0;\n    }\n    &:nth-child(2), &:nth-child(3) {\n      top: 8px;\n    }\n    &:nth-child(4) {\n      top: 16px;\n    }\n    }\n\n  ", "\n"], ["\n  display: block;\n  position: relative;\n  height: 20px;\n  width: 20px;\n  z-index: 101;\n\n  span {\n    display: block;\n    position: absolute;\n    height: 2px;\n    width: 100%;\n    background: ", ";\n    border-radius: 9px;\n    opacity: 1;\n    left: 0;\n    transform: rotate(0deg);\n    transition: .25s ease-in-out;\n\n    &:first-child {\n      top: 0;\n    }\n    &:nth-child(2), &:nth-child(3) {\n      top: 8px;\n    }\n    &:nth-child(4) {\n      top: 16px;\n    }\n    }\n\n  ",
    "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var isMenuOpen = _a.isMenuOpen;
    return isMenuOpen && "\n      span:first-child {\n      top: 8px;\n      width: 0;\n      left: 50%;\n    }\n\n    span:nth-child(2) {\n      transform: rotate(45deg);\n    }\n\n    span:nth-child(3) {\n      transform: rotate(-45deg);\n    }\n\n    span:nth-child(4) {\n      top: 8px;\n      width: 0;\n      left: 50%;\n    }\n  ";
});
var templateObject_1;
