var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import LogoIcon from 'app/atoms/icons/factris-logo.svg';
import { Space } from 'app/styles';
import { Link } from 'app/atoms/link';
import { getPathWithDealIdQueryString, path } from 'app/navigation';
import i18n from 'app/translations';
import { useLogout } from 'app/auth/auth-providers';
import { LanguageSelector } from './atoms/language-selector';
import { DesktopHeaderLink } from './atoms/desktop-header-link';
import { isAuthenticatedVar } from 'app/apollo/cache';
var DesktopHeaderContent = function (_a) {
    var isFixedHeader = _a.isFixedHeader;
    var t = useTranslation().t;
    var logout = useLogout().logout;
    var isAuthenticated = useReactiveVar(isAuthenticatedVar);
    var startPage = getPathWithDealIdQueryString(path.startPage);
    var _b = useState(i18n.language), lng = _b[0], setLanguage = _b[1];
    useEffect(function () {
        setLanguage(i18n.language);
    }, [i18n.language]);
    return (React.createElement(Flex, { width: 1, justifyContent: "space-between", alignItems: "center" },
        React.createElement(Link, { to: "https://www.factris.com/" + lng + "/", externalLink: true },
            React.createElement(StyledLogoIcon, { title: "https://www.factris.com/" + lng + "/", isFixedHeader: isFixedHeader })),
        React.createElement(Flex, { justifyContent: "space-between", alignItems: "center" },
            isAuthenticated && (React.createElement(DesktopHeaderLink, { isFixedHeader: isFixedHeader, dataTest: "header-your-application", path: startPage, linkText: t('header.start') })),
            React.createElement(Box, { mx: Space.S16 },
                React.createElement(LanguageSelector, { isFixedHeader: isFixedHeader })),
            isAuthenticated
                ? React.createElement(DesktopHeaderLink, { isFixedHeader: isFixedHeader, dataTest: "header-logout", path: path.loginPage, linkText: t('header.logout'), onClick: logout })
                : React.createElement(DesktopHeaderLink, { isFixedHeader: isFixedHeader, dataTest: "header-login", path: path.loginPage, linkText: t('header.login') }))));
};
var StyledLogoIcon = styled(function (_a) {
    var isFixedHeader = _a.isFixedHeader, props = __rest(_a, ["isFixedHeader"]);
    return React.createElement(LogoIcon, __assign({}, props));
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  transition: all .4s ease-in-out;\n"], ["\n  width: ", ";\n  height: ", ";\n  transition: all .4s ease-in-out;\n"])), function (_a) {
    var isFixedHeader = _a.isFixedHeader;
    return isFixedHeader ? '180px' : '202px';
}, function (_a) {
    var isFixedHeader = _a.isFixedHeader;
    return isFixedHeader ? '33px' : '37px';
});
export default DesktopHeaderContent;
var templateObject_1;
