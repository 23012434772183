var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Paragraph } from 'app/atoms/typography';
import LongArrowIcon from 'app/atoms/icons/arrow-long.svg';
export var MobileHeaderLink = function (_a) {
    var onClick = _a.onClick, path = _a.path, linkText = _a.linkText, dataTest = _a.dataTest;
    return (React.createElement(StyledLink, { to: path, onClick: onClick, "data-test": dataTest },
        React.createElement(Paragraph, null, linkText),
        React.createElement(LongArrowIcon, null)));
};
var StyledLink = styled(NavLink)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.space.S16 + " 0";
});
var templateObject_1;
