import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import App from 'app/app';
import { theme, GlobalStyle } from 'app/styles';
import ScrollToTop from 'app/atoms/scroll-to-top';
import i18n from 'app/translations';
import 'regenerator-runtime/runtime';
import 'core-js/stable';
var Index = function () { return (React.createElement(BrowserRouter, null,
    React.createElement(ThemeProvider, { theme: theme },
        React.createElement(GlobalStyle, null),
        React.createElement(ScrollToTop, null),
        React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(App, null))))); };
render(React.createElement(Index, null), document.getElementById('app'));
