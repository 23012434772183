var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { hex2rgba } from 'app/helpers/hex-to-rgba';
export var StyledSpinner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n\n  ", "\n\n  svg {\n    stroke: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n\n  ",
    "\n\n  svg {\n    stroke: ", ";\n  }\n"])), function (_a) {
    var overlay = _a.overlay, theme = _a.theme;
    return overlay && "\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    z-index: 3;\n    background-color: " + hex2rgba(theme.colors.white, 0.5) + ";\n  ";
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary_violet;
});
var templateObject_1;
