var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Redirect, Route } from 'react-router';
import { Spinner } from 'app/atoms/spinner';
import { path } from 'app/navigation';
import { useAuthContext } from 'app/context/auth-context';
var PrivateRoute = function (_a) {
    var Component = _a.component, restrictedContent = _a.restrictedContent, rest = __rest(_a, ["component", "restrictedContent"]);
    var _b = useAuthContext().currentUser, data = _b.data, loading = _b.loading, error = _b.error;
    if (loading)
        return React.createElement(Spinner, null);
    var renderComponent = function (props) {
        if (!data || !!error) {
            return (React.createElement(Redirect, { to: {
                    pathname: path.loginPage,
                    state: { from: props.location },
                } }));
        }
        var _a = data.currentAccount, individual = _a.individual, onboardingStarted = _a.onboardingStarted, companyCountryCode = _a.companyCountryCode, dealId = _a.dealId;
        var isUserBlocked = companyCountryCode !== 'NL' && companyCountryCode !== 'PL' && individual;
        if ((isUserBlocked || !onboardingStarted) && restrictedContent) {
            return (React.createElement(Redirect, { to: {
                    pathname: "" + path.startPage,
                    search: "?d=" + dealId,
                    state: { from: props.location },
                } }));
        }
        return React.createElement(Component, __assign({}, props));
    };
    return (React.createElement(Route, __assign({}, rest, { render: renderComponent })));
};
export default PrivateRoute;
