import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
var ScrollToTopController = function () {
    var pathname = useLocation().pathname;
    useEffect(function () {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        catch (error) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);
    return null;
};
export default ScrollToTopController;
