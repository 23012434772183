var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { colors, theme } from 'app/styles';
export var getCustomStyle = function (_a) {
    var hasError = _a.hasError, isClearable = _a.isClearable;
    return ({
        container: function (base) { return (__assign(__assign({}, base), { fontSize: '18px', fontFamily: theme.fonts.fontFamily, lineHeight: 1.4 })); },
        control: function (base, state) { return (__assign(__assign({}, base), { cursor: 'pointer', height: '62px', borderStyle: 'solid', borderWidth: theme.borderWidth, borderColor: hasError ? colors.primary_rust : state.isFocused
                ? colors.secondary_purple
                : colors.primary_navy_light, boxShadow: 'none', backgroundColor: colors.bgLight, ':hover': {
                border: "solid " + theme.borderWidth + " " + colors.secondary_purple,
            } })); },
        valueContainer: function (base) { return (__assign(__assign({}, base), { padding: '2px 16px', overflow: 'hidden', flexWrap: 'nowrap', textOverflow: 'ellipsis', color: colors.text })); },
        menu: function (base) { return (__assign(__assign({}, base), { borderRadius: theme.radii.small, boxShadow: 'none', border: "solid " + theme.borderWidth + " " + colors.primary_navy_light, zIndex: 5 })); },
        dropdownIndicator: function (base) { return (__assign(__assign({}, base), { alignItems: 'center', justifyContent: 'center', svg: {
                fill: colors.text_gray,
                width: '25px',
                height: '25px',
            } })); },
        menuList: function (base) { return (__assign(__assign({}, base), { background: colors.white })); },
        option: function (base, state) { return (__assign(__assign({}, base), { ':hover': {
                backgroundColor: colors.bgLight,
            }, backgroundColor: (state.isSelected || state.isFocused) ? colors.bgLight : 'transparent', padding: '16px 17px', cursor: 'pointer', color: colors.text })); },
        indicatorSeparator: function (base, state) { return (__assign(__assign({}, base), { display: (isClearable && !state.isDisabled) ? 'inline-block' : 'none', backgroundColor: colors.primary_navy_light, width: '2px' })); },
        clearIndicator: function (base) { return (__assign(__assign({}, base), { cursor: 'pointer', svg: {
                fill: colors.text_gray,
                width: '25px',
            } })); },
        placeholder: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { display: 'flex', color: colors.text })); },
    });
};
