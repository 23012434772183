var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import { LOGIN_USER, SIGNUP_USER, LOGOUT, CONFIRM_EMAIL, CHANGE_PASSWORD } from 'app/api/mutations';
import { GET_CURRENT_ACCOUNT_INFO } from 'app/api/queries';
import * as storageUtils from 'app/helpers/storage-utils';
import { path } from 'app/navigation';
import { isAuthenticatedVar } from 'app/apollo/cache';
import { useState } from 'react';
import { setRequestError } from 'app/helpers/utils';
export var useLogin = function () {
    var push = useHistory().push;
    var _a = useState(''), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = useMutation(LOGIN_USER, {
        onCompleted: function (_a) {
            var dealId = _a.signIn.dealId;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    errorMessage && setErrorMessage('');
                    storageUtils.setDealId(dealId);
                    push(path.startPage + "?d=" + dealId);
                    return [2];
                });
            });
        },
        onError: function (_a) {
            var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
            setRequestError({ graphQLErrors: graphQLErrors, networkError: networkError }, setErrorMessage);
        },
    }), singIn = _b[0], loading = _b[1].loading;
    var login = function (email, password) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, singIn({ variables: { email: email, password: password } })];
            case 1: return [2, _a.sent()];
        }
    }); }); };
    return { login: login, loading: loading, errorMessage: errorMessage };
};
export var useSignUp = function (resetReCAPTCHA) {
    var push = useHistory().push;
    var _a = useState(''), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = useMutation(SIGNUP_USER, {
        onCompleted: function (data) {
            return __awaiter(this, void 0, void 0, function () {
                var dealId;
                return __generator(this, function (_a) {
                    dealId = data.createClient.authenticationInfo.dealId;
                    storageUtils.setDealId(dealId);
                    push(path.businessDetailsPage + "?d=" + dealId);
                    errorMessage && setErrorMessage('');
                    return [2];
                });
            });
        },
        onError: function (_a) {
            var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
            resetReCAPTCHA();
            setRequestError({ graphQLErrors: graphQLErrors, networkError: networkError }, setErrorMessage);
        },
    }), register = _b[0], loading = _b[1].loading;
    var signUp = function (variables) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, register({ variables: variables })];
            case 1: return [2, _a.sent()];
        }
    }); }); };
    return { signUp: signUp, loading: loading, errorMessage: errorMessage };
};
export var useConfirmEmail = function () {
    var push = useHistory().push;
    var confirmUserEmail = useMutation(CONFIRM_EMAIL, {
        onCompleted: function (data) {
            return __awaiter(this, void 0, void 0, function () {
                var dealId;
                return __generator(this, function (_a) {
                    dealId = data.confirmEmail.authenticationInfo.dealId;
                    storageUtils.setDealId(dealId);
                    push(path.businessDetailsPage + "?d=" + dealId);
                    return [2];
                });
            });
        },
        onError: function () {
            push(path.loginPage);
        },
    })[0];
    var confirmEmail = function (confirmationToken) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, confirmUserEmail({ variables: { confirmationToken: confirmationToken } })];
            case 1: return [2, _a.sent()];
        }
    }); }); };
    return { confirmEmail: confirmEmail };
};
export var useLogout = function () {
    var _a = useMutation(LOGOUT, {
        onCompleted: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            storageUtils.clearLocalStorage();
                            return [4, client.clearStore()];
                        case 1:
                            _a.sent();
                            isAuthenticatedVar(false);
                            return [2];
                    }
                });
            });
        },
    }), logout = _a[0], client = _a[1].client;
    return { logout: logout };
};
export var useCurrentUser = function () { return useQuery(GET_CURRENT_ACCOUNT_INFO, {
    onCompleted: function () {
        isAuthenticatedVar(true);
    },
    fetchPolicy: 'network-only',
}); };
export var useChangePassword = function () {
    var push = useHistory().push;
    var _a = useState(''), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = useMutation(CHANGE_PASSWORD, {
        onCompleted: function () {
            push(path.loginPage);
            setErrorMessage('');
        },
        onError: function (_a) {
            var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
            setRequestError({ graphQLErrors: graphQLErrors, networkError: networkError }, setErrorMessage);
        },
    }), changeUserPassword = _b[0], loading = _b[1].loading;
    var changePassword = function (variables) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, changeUserPassword({ variables: variables })];
            case 1: return [2, _a.sent()];
        }
    }); }); };
    return { changePassword: changePassword, loading: loading, errorMessage: errorMessage };
};
