import React, { memo } from 'react';
import { StyledHamburgerMenu } from './styled-hamburger-menu';
var HamburgerMenu = memo(function (_a) {
    var toggleMenu = _a.toggleMenu, isMenuOpen = _a.isMenuOpen;
    return (React.createElement(StyledHamburgerMenu, { onClick: toggleMenu, isMenuOpen: isMenuOpen },
        React.createElement("span", null),
        React.createElement("span", null),
        React.createElement("span", null),
        React.createElement("span", null)));
});
HamburgerMenu.displayName = 'HamburgerMenu';
export default HamburgerMenu;
