import { Color } from 'app/styles/colors';
import { theme } from 'app/styles/theme';
export var typographyStyles = {
    H1: {
        fontSize: '46px',
        fontWeight: 500,
        lineHeight: 1.4,
        letterSpacing: '0.25px',
        fontFamily: theme.fonts.fontFamilyHeading,
        as: 'h1',
        margin: 0,
    },
    H2: {
        fontSize: '36px',
        fontWeight: 700,
        lineHeight: '50px',
        letterSpacing: '0.25px',
        fontFamily: theme.fonts.fontFamilyHeading,
        as: 'h2',
        margin: 0,
    },
    H3: {
        fontSize: '25px',
        fontWeight: 700,
        letterSpacing: 0,
        lineHeight: '35px',
        fontFamily: theme.fonts.fontFamilyHeading,
        as: 'h3',
        margin: 0,
    },
    H4: {
        fontSize: '21px',
        fontWeight: 700,
        letterSpacing: '0.15px',
        lineHeight: '29px',
        fontFamily: theme.fonts.fontFamilyHeading,
        as: 'h4',
        margin: 0,
    },
    SubHeading: {
        fontSize: '24px',
        fontWeight: 700,
        letterSpacing: '0.4px',
        lineHeight: '32px',
        fontFamily: theme.fonts.fontFamily,
        as: 'p',
        margin: 0,
    },
    Paragraph: {
        fontSize: '18px',
        fontWeight: 400,
        letterSpacing: '0.5px',
        lineHeight: '22px',
        fontFamily: theme.fonts.fontFamily,
        as: 'p',
        margin: 0,
    },
    LabelText: {
        fontSize: '18px',
        fontWeight: 500,
        letterSpacing: '0.5px',
        lineHeight: '22px',
        fontFamily: theme.fonts.fontFamily,
        as: 'label',
        margin: 0,
    },
    SupportiveText: {
        fontSize: '18px',
        fontWeight: 400,
        letterSpacing: '0.5px',
        lineHeight: '22px',
        fontFamily: theme.fonts.fontFamily,
        as: 'label',
        margin: 0,
    },
    ErrorText: {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: theme.fonts.fontFamily,
        letterSpacing: '0.55px',
        lineHeight: '20px',
        color: Color.PRIMARY_RUST,
        as: 'p',
        margin: 0,
    }
};
