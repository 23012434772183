import { withLanguage } from 'app/translations';
import * as storageUtils from 'app/helpers/storage-utils';
export var path = {
    loginPage: withLanguage('/login'),
    signupPage: withLanguage('/sign-up'),
    confirmEmail: withLanguage('/confirm-email/:token'),
    startPage: withLanguage('/your-application'),
    startPageSuccess: withLanguage('/your-application/success'),
    businessDetailsPage: withLanguage('/business-details'),
    financialReviewPage: withLanguage('/financial-review'),
    stakeholdersPage: withLanguage('/stakeholders'),
    invoicePage: withLanguage('/your-customers'),
    resetPassword: withLanguage('/reset-password'),
    changePassword: withLanguage('/change-password/:token'),
};
export var getPathWithDealIdQueryString = function (path) {
    var dealId = storageUtils.getDealId();
    return !dealId || dealId === 'null' ? path : path + "?d=" + dealId;
};
