import { useLayoutEffect } from 'react';
var useLockBodyScroll = function (locked) {
    useLayoutEffect(function () {
        var originalStyle = window.getComputedStyle(document.body).overflow;
        if (locked) {
            document.body.style.overflow = 'hidden';
        }
        return function () { return document.body.style.overflow = originalStyle; };
    }, [locked]);
};
export default useLockBodyScroll;
