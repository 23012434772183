var D = 'd';
export function getDealId() {
    return localStorage.getItem(D);
}
export function setDealId(dealId) {
    localStorage.setItem(D, dealId);
}
export function clearLocalStorage() {
    localStorage.clear();
}
