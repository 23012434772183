import { useState, useCallback } from 'react';
var useToggle = function (state) {
    var _a = useState(state), value = _a[0], setValue = _a[1];
    var toggle = useCallback(function (nextValue) {
        if (typeof nextValue !== 'undefined') {
            setValue(nextValue);
            return;
        }
        setValue(!value);
    }, [value]);
    return [value, toggle];
};
export default useToggle;
