var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import DynamicStyledSystemComponent from './dynamic-styled-system-component';
import { typographyStyles } from './typography-styles';
var createComponent = function (textStyle, displayName) {
    var component = function (props) { return (React.createElement(DynamicStyledSystemComponent, __assign({}, textStyle, props), props.children)); };
    component.displayName = displayName;
    return memo(component);
};
export var Typography = {
    H1: createComponent(typographyStyles.H1, 'H1'),
    H2: createComponent(typographyStyles.H2, 'H2'),
    H3: createComponent(typographyStyles.H3, 'H3'),
    SubHeading: createComponent(typographyStyles.SubHeading, 'SubHeading'),
    Paragraph: createComponent(typographyStyles.Paragraph, 'Paragraph'),
    SupportiveText: createComponent(typographyStyles.SupportiveText, 'SupportiveText'),
    LabelText: createComponent(typographyStyles.LabelText, 'LabelText'),
    ErrorText: createComponent(typographyStyles.ErrorText, 'ErrorText'),
};
