var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Paragraph } from 'app/atoms/typography';
import { Color } from 'app/styles';
export var DesktopHeaderLink = function (_a) {
    var onClick = _a.onClick, path = _a.path, linkText = _a.linkText, dataTest = _a.dataTest, isFixedHeader = _a.isFixedHeader;
    return (React.createElement(StyledLink, { to: path, onClick: onClick, "data-test": dataTest, isFixedHeader: isFixedHeader },
        React.createElement(Paragraph, { fontWeight: 500, fontSize: "inherit", color: Color.WHITE }, linkText)));
};
var StyledLink = styled(function (_a) {
    var isFixedHeader = _a.isFixedHeader, props = __rest(_a, ["isFixedHeader"]);
    return React.createElement(NavLink, __assign({}, props));
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  padding: ", ";\n  white-space: nowrap;\n  transition: all .4s ease-in-out;\n  font-size: ", ";\n      \n  &:hover {\n    opacity: .7;\n    transition: all .4s ease-in-out;\n  }\n"], ["\n  position: relative;\n  padding: ", ";\n  white-space: nowrap;\n  transition: all .4s ease-in-out;\n  font-size: ", ";\n      \n  &:hover {\n    opacity: .7;\n    transition: all .4s ease-in-out;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.space.S24 + " 0";
}, function (_a) {
    var isFixedHeader = _a.isFixedHeader;
    return isFixedHeader ? '15px' : '18px';
});
var templateObject_1;
