import { hex2rgba } from 'app/helpers/hex-to-rgba';
export var Color;
(function (Color) {
    Color["PRIMARY_NAVY"] = "primary_navy";
    Color["PRIMARY_VIOLET"] = "primary_violet";
    Color["PRIMARY_GREEN"] = "primary_green";
    Color["PRIMARY_RUST"] = "primary_rust";
    Color["SECONDARY_PURPLE"] = "secondary_purple";
    Color["SECONDARY_ORCHID"] = "secondary_orchid";
    Color["SECONDARY_ORANGE"] = "secondary_orange";
    Color["SECONDARY_PINK"] = "secondary_pink";
    Color["SECONDARY_RED"] = "secondary_red";
    Color["SECONDARY_PURPLE_DARK"] = "secondary_purple_dark";
    Color["PRIMARY_GREEN_LIGHT"] = "primary_green_light";
    Color["SECONDARY_ORANGE_LIGHT"] = "secondary_orange_light";
    Color["PRIMARY_NAVY_LIGHT"] = "primary_navy_light";
    Color["WHITE"] = "white";
    Color["GRAY"] = "gray";
    Color["TEXT"] = "text";
    Color["TEXT_GRAY"] = "text_gray";
    Color["BG_LIGHT"] = "bgLight";
    Color["BG_ORANGE"] = "transparentSecondaryOrange";
    Color["BG_GREEN"] = "transparentPrimaryGreen";
})(Color || (Color = {}));
export var colors = {
    primary_navy: '#001194',
    primary_violet: '#38047C',
    primary_green: '#246051',
    primary_rust: '#A32C00',
    secondary_purple: '#B35DFF',
    secondary_orange: '#FF8A00',
    secondary_pink: '#FF7CC6',
    secondary_red: '#FF4300',
    white: '#ffffff',
    gray: '#F7F8FC',
    secondary_purple_dark: '#8F4ACC',
    primary_navy_light: '#D8DAEE',
    primary_green_light: '#DDE6E4',
    secondary_orange_light: '#FFE8CC',
    secondary_orchid: '#CE68EC',
    text: '#353540',
    text_gray: '#727282',
    bgLight: hex2rgba('#D8DAEE', 0.2),
    transparentSecondaryOrange: hex2rgba('#FF8A00', 0.1),
    transparentPrimaryGreen: hex2rgba('#246051', 0.1),
};
