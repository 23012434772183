import { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { path } from 'app/navigation';
import { Color } from 'app/styles';
var getBackgroundColor = function (location) {
    switch (location) {
        case path.startPage:
            return Color.PRIMARY_VIOLET;
        case path.loginPage:
            return Color.PRIMARY_VIOLET;
        case path.signupPage:
            return Color.PRIMARY_VIOLET;
        case path.resetPassword:
            return Color.PRIMARY_VIOLET;
        case path.businessDetailsPage:
            return Color.PRIMARY_VIOLET;
        case path.invoicePage:
            return Color.PRIMARY_NAVY;
        case path.financialReviewPage:
            return Color.PRIMARY_RUST;
        case path.stakeholdersPage:
            return Color.PRIMARY_GREEN;
        default:
            return Color.PRIMARY_VIOLET;
    }
};
export function useBackgroundColor() {
    var pathname = useLocation().pathname;
    var _a = useState(getBackgroundColor(pathname)), color = _a[0], setColor = _a[1];
    useLayoutEffect(function () {
        if (pathname) {
            setColor(getBackgroundColor(pathname));
        }
        return function () {
            setColor(Color.PRIMARY_VIOLET);
        };
    }, [pathname]);
    return color;
}
