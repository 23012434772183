var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fontWeight, space, layout } from 'styled-system';
export default styled('a')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: ", ";\n  color: ", ";\n  font-size: 18px;\n\n   &:hover {\n    text-decoration: ", ";\n    color: ", ";\n  }\n\n  ", "\n  ", "\n  ", "\n"], ["\n  text-decoration: ", ";\n  color: ", ";\n  font-size: 18px;\n\n   &:hover {\n    text-decoration: ", ";\n    color: ", ";\n  }\n\n  ", "\n  ", "\n  ", "\n"])), function (_a) {
    var underline = _a.underline;
    return underline ? 'underline' : 'none';
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary_purple;
}, function (_a) {
    var underline = _a.underline;
    return underline ? 'underline' : 'none';
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary_purple_dark;
}, fontWeight, space, layout);
var templateObject_1;
