var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { colors } from './colors';
import { space } from './space';
import { borderRadius } from './border-radius';
import { breakPointsPx } from './breakpoints';
import { mediaQueries } from '../helpers/media-queries';
export var theme = {
    colors: __assign({}, colors),
    space: __assign({}, space),
    fontSize: '100%',
    fonts: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontFamilyHeading: 'Lora, Helvetica, Arial, sans-serif',
    },
    radii: __assign({}, borderRadius),
    borderWidth: '2px',
    breakpoints: [
        breakPointsPx.md,
        breakPointsPx.lg,
        breakPointsPx.xl
    ],
    mediaQueries: __assign({}, mediaQueries),
};
