import { useCallback, useEffect, useRef } from 'react';
export var useClickOutside = function (onClose) {
    var ref = useRef(null);
    var escapeListener = useCallback(function (e) {
        if (e.key === 'Escape') {
            onClose();
        }
    }, []);
    var clickListener = useCallback(function (e) {
        if (!ref.current.contains(e.target)) {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    }, [ref.current]);
    useEffect(function () {
        document.addEventListener('click', clickListener);
        document.addEventListener('keyup', escapeListener);
        return function () {
            document.removeEventListener('click', clickListener);
            document.removeEventListener('keyup', escapeListener);
        };
    }, []);
    return ref;
};
