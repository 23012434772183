export var AllCountries;
(function (AllCountries) {
    AllCountries["AF"] = "Afghanistan";
    AllCountries["AX"] = "AlandIslands";
    AllCountries["AL"] = "Albania";
    AllCountries["DZ"] = "Algeria";
    AllCountries["AS"] = "American Samoa";
    AllCountries["AD"] = "Andorra";
    AllCountries["AO"] = "Angola";
    AllCountries["AI"] = "Anguilla";
    AllCountries["AQ"] = "Antarctica";
    AllCountries["AG"] = "Antigua and Barbuda";
    AllCountries["AR"] = "Argentina";
    AllCountries["AM"] = "Armenia";
    AllCountries["AW"] = "Aruba";
    AllCountries["AU"] = "Australia";
    AllCountries["AT"] = "Austria";
    AllCountries["AZ"] = "Azerbaijan";
    AllCountries["BS"] = "Bahamas";
    AllCountries["BH"] = "Bahrain";
    AllCountries["BD"] = "Bangladesh";
    AllCountries["BB"] = "Barbados";
    AllCountries["BY"] = "Belarus";
    AllCountries["BE"] = "Belgium";
    AllCountries["BZ"] = "Belize";
    AllCountries["BJ"] = "Benin";
    AllCountries["BM"] = "Bermuda";
    AllCountries["BT"] = "Bhutan";
    AllCountries["BO"] = "Bolivia";
    AllCountries["BA"] = "Bosnia and Herzegovina";
    AllCountries["BW"] = "Botswana";
    AllCountries["BV"] = "Bouvet Island";
    AllCountries["BR"] = "Brazil";
    AllCountries["IO"] = "British Indian Ocean Territory";
    AllCountries["BN"] = "Brunei Darussalam";
    AllCountries["BG"] = "Bulgaria";
    AllCountries["BF"] = "Burkina Faso";
    AllCountries["BI"] = "Burundi";
    AllCountries["KH"] = "Cambodia";
    AllCountries["CM"] = "Cameroon";
    AllCountries["CA"] = "Canada";
    AllCountries["CV"] = "Cape Verde";
    AllCountries["KY"] = "Cayman Islands";
    AllCountries["CF"] = "Central African Republic";
    AllCountries["TD"] = "Chad";
    AllCountries["CL"] = "Chile";
    AllCountries["CN"] = "China";
    AllCountries["CX"] = "Christmas Island";
    AllCountries["CC"] = "Cocos Keeling Islands";
    AllCountries["CO"] = "Colombia";
    AllCountries["KM"] = "Comoros";
    AllCountries["CG"] = "Congo";
    AllCountries["CD"] = "Congo Democratic Republic";
    AllCountries["CK"] = "Cook Islands";
    AllCountries["CR"] = "Costa Rica";
    AllCountries["CI"] = "CoteDIvoire";
    AllCountries["HR"] = "Croatia";
    AllCountries["CU"] = "Cuba";
    AllCountries["CY"] = "Cyprus";
    AllCountries["CZ"] = "CzechRepublic";
    AllCountries["DK"] = "Denmark";
    AllCountries["DJ"] = "Djibouti";
    AllCountries["DM"] = "Dominica";
    AllCountries["DO"] = "Dominican Republic";
    AllCountries["EC"] = "Ecuador";
    AllCountries["EG"] = "Egypt";
    AllCountries["SV"] = "El Salvador";
    AllCountries["GQ"] = "Equatorial Guinea";
    AllCountries["ER"] = "Eritrea";
    AllCountries["EE"] = "Estonia";
    AllCountries["ET"] = "Ethiopia";
    AllCountries["FK"] = "Falkland Islands";
    AllCountries["FO"] = "Faroe Islands";
    AllCountries["FJ"] = "Fiji";
    AllCountries["FI"] = "Finland";
    AllCountries["FR"] = "France";
    AllCountries["GF"] = "French Guiana";
    AllCountries["PF"] = "French Polynesia";
    AllCountries["TF"] = "French Southern Territories";
    AllCountries["GA"] = "Gabon";
    AllCountries["GM"] = "Gambia";
    AllCountries["GE"] = "Georgia";
    AllCountries["DE"] = "Germany";
    AllCountries["GH"] = "Ghana";
    AllCountries["GI"] = "Gibraltar";
    AllCountries["GR"] = "Greece";
    AllCountries["GL"] = "Greenland";
    AllCountries["GD"] = "Grenada";
    AllCountries["GP"] = "Guadeloupe";
    AllCountries["GU"] = "Guam";
    AllCountries["GT"] = "Guatemala";
    AllCountries["GG"] = "Guernsey";
    AllCountries["GN"] = "Guinea";
    AllCountries["GW"] = "Guinea Bissau";
    AllCountries["GY"] = "Guyana";
    AllCountries["HT"] = "Haiti";
    AllCountries["HM"] = "Heard Island Mcdonald Islands";
    AllCountries["VA"] = "Holy See Vatican City State";
    AllCountries["HN"] = "Honduras";
    AllCountries["HK"] = "HongKong";
    AllCountries["HU"] = "Hungary";
    AllCountries["IS"] = "Iceland";
    AllCountries["IN"] = "India";
    AllCountries["ID"] = "Indonesia";
    AllCountries["IR"] = "Iran";
    AllCountries["IQ"] = "Iraq";
    AllCountries["IE"] = "Ireland";
    AllCountries["IM"] = "Isle Of Man";
    AllCountries["IL"] = "Israel";
    AllCountries["IT"] = "Italy";
    AllCountries["JM"] = "Jamaica";
    AllCountries["JP"] = "Japan";
    AllCountries["JE"] = "Jersey";
    AllCountries["JO"] = "Jordan";
    AllCountries["KZ"] = "Kazakhstan";
    AllCountries["KE"] = "Kenya";
    AllCountries["KI"] = "Kiribati";
    AllCountries["KR"] = "Korea";
    AllCountries["KW"] = "Kuwait";
    AllCountries["KG"] = "Kyrgyzstan";
    AllCountries["LA"] = "Lao Peoples Democratic Republic";
    AllCountries["LV"] = "Latvia";
    AllCountries["LB"] = "Lebanon";
    AllCountries["LS"] = "Lesotho";
    AllCountries["LR"] = "Liberia";
    AllCountries["LY"] = "Libyan Arab Jamahiriya";
    AllCountries["LI"] = "Liechtenstein";
    AllCountries["LT"] = "Lithuania";
    AllCountries["LU"] = "Luxembourg";
    AllCountries["MO"] = "Macao";
    AllCountries["MK"] = "Macedonia";
    AllCountries["MG"] = "Madagascar";
    AllCountries["MW"] = "Malawi";
    AllCountries["MY"] = "Malaysia";
    AllCountries["MV"] = "Maldives";
    AllCountries["ML"] = "Mali";
    AllCountries["MT"] = "Malta";
    AllCountries["MH"] = "Marshall Islands";
    AllCountries["MQ"] = "Martinique";
    AllCountries["MR"] = "Mauritania";
    AllCountries["MU"] = "Mauritius";
    AllCountries["YT"] = "Mayotte";
    AllCountries["MX"] = "Mexico";
    AllCountries["FM"] = "Micronesia";
    AllCountries["MD"] = "Moldova";
    AllCountries["MC"] = "Monaco";
    AllCountries["MN"] = "Mongolia";
    AllCountries["ME"] = "Montenegro";
    AllCountries["MS"] = "Montserrat";
    AllCountries["MA"] = "Morocco";
    AllCountries["MZ"] = "Mozambique";
    AllCountries["MM"] = "Myanmar";
    AllCountries["NA"] = "Namibia";
    AllCountries["NR"] = "Nauru";
    AllCountries["NP"] = "Nepal";
    AllCountries["NL"] = "Netherlands";
    AllCountries["AN"] = "Netherlands Antilles";
    AllCountries["NC"] = "New Caledonia";
    AllCountries["NZ"] = "New Zealand";
    AllCountries["NI"] = "Nicaragua";
    AllCountries["NE"] = "Niger";
    AllCountries["NG"] = "Nigeria";
    AllCountries["NU"] = "Niue";
    AllCountries["NF"] = "Norfolk Island";
    AllCountries["MP"] = "Northern Mariana Islands";
    AllCountries["NO"] = "Norway";
    AllCountries["OM"] = "Oman";
    AllCountries["PK"] = "Pakistan";
    AllCountries["PW"] = "Palau";
    AllCountries["PS"] = "Palestinian Territory";
    AllCountries["PA"] = "Panama";
    AllCountries["PG"] = "Papua New Guinea";
    AllCountries["PY"] = "Paraguay";
    AllCountries["PE"] = "Peru";
    AllCountries["PH"] = "Philippines";
    AllCountries["PN"] = "Pitcairn";
    AllCountries["PL"] = "Poland";
    AllCountries["PT"] = "Portugal";
    AllCountries["PR"] = "Puerto Rico";
    AllCountries["QA"] = "Qatar";
    AllCountries["RE"] = "Reunion";
    AllCountries["RO"] = "Romania";
    AllCountries["RU"] = "Russian Federation";
    AllCountries["RW"] = "Rwanda";
    AllCountries["BL"] = "Saint Barthelemy";
    AllCountries["SH"] = "Saint Helena";
    AllCountries["KN"] = "Saint Kitts And Nevis";
    AllCountries["LC"] = "Saint Lucia";
    AllCountries["MF"] = "Saint Martin";
    AllCountries["PM"] = "Saint Pierre And Miquelon";
    AllCountries["VC"] = "Saint Vincent And Grenadines";
    AllCountries["WS"] = "Samoa";
    AllCountries["SM"] = "San Marino";
    AllCountries["ST"] = "Sao Tome And Principe";
    AllCountries["SA"] = "Saudi Arabia";
    AllCountries["SN"] = "Senegal";
    AllCountries["RS"] = "Serbia";
    AllCountries["SC"] = "Seychelles";
    AllCountries["SL"] = "Sierra Leone";
    AllCountries["SG"] = "Singapore";
    AllCountries["SK"] = "Slovakia";
    AllCountries["SI"] = "Slovenia";
    AllCountries["SB"] = "Solomon Islands";
    AllCountries["SO"] = "Somalia";
    AllCountries["ZA"] = "South Africa";
    AllCountries["GS"] = "South Georgia And Sandwich Island";
    AllCountries["ES"] = "Spain";
    AllCountries["LK"] = "Sri Lanka";
    AllCountries["SD"] = "Sudan";
    AllCountries["SR"] = "Suriname";
    AllCountries["SJ"] = "Svalbard And Jan Mayen";
    AllCountries["SZ"] = "Swaziland";
    AllCountries["SE"] = "Sweden";
    AllCountries["CH"] = "Switzerland";
    AllCountries["SY"] = "Syrian Arab Republic";
    AllCountries["TW"] = "Taiwan";
    AllCountries["TJ"] = "Tajikistan";
    AllCountries["TZ"] = "Tanzania";
    AllCountries["TH"] = "Thailand";
    AllCountries["TL"] = "TimorLeste";
    AllCountries["TG"] = "Togo";
    AllCountries["TK"] = "Tokelau";
    AllCountries["TO"] = "Tonga";
    AllCountries["TT"] = "Trinidad And Tobago";
    AllCountries["TN"] = "Tunisia";
    AllCountries["TR"] = "Turkey";
    AllCountries["TM"] = "Turkmenistan";
    AllCountries["TC"] = "Turks And Caicos Islands";
    AllCountries["TV"] = "Tuvalu";
    AllCountries["UG"] = "Uganda";
    AllCountries["UA"] = "Ukraine";
    AllCountries["AE"] = "United Arab Emirates";
    AllCountries["GB"] = "United Kingdom";
    AllCountries["US"] = "United States";
    AllCountries["UM"] = "United States Outlying Islands";
    AllCountries["UY"] = "Uruguay";
    AllCountries["UZ"] = "Uzbekistan";
    AllCountries["VU"] = "Vanuatu";
    AllCountries["VE"] = "Venezuela";
    AllCountries["VN"] = "Vietnam";
    AllCountries["VG"] = "Virgin Islands British";
    AllCountries["VI"] = "Virgin Islands US";
    AllCountries["WF"] = "Wallis And Futuna";
    AllCountries["EH"] = "Western Sahara";
    AllCountries["YE"] = "Yemen";
    AllCountries["ZM"] = "Zambia";
    AllCountries["ZW"] = "Zimbabw";
})(AllCountries || (AllCountries = {}));
export var CustomCountries;
(function (CustomCountries) {
    CustomCountries["AU"] = "Australia";
    CustomCountries["AT"] = "Austria";
    CustomCountries["BE"] = "Belgium";
    CustomCountries["CA"] = "Canada";
    CustomCountries["CL"] = "Chile";
    CustomCountries["CZ"] = "Czech Republic";
    CustomCountries["DK"] = "Denmark";
    CustomCountries["DE"] = "Germany";
    CustomCountries["EE"] = "Estonia";
    CustomCountries["FI"] = "Finland";
    CustomCountries["FR"] = "France";
    CustomCountries["GR"] = "Greece";
    CustomCountries["HU"] = "Hungary";
    CustomCountries["IE"] = "Ireland";
    CustomCountries["IS"] = "Iceland";
    CustomCountries["IL"] = "Israel";
    CustomCountries["IT"] = "Italy";
    CustomCountries["JP"] = "Japan";
    CustomCountries["KR"] = "Korea";
    CustomCountries["LV"] = "Latvia";
    CustomCountries["LT"] = "Lithuania";
    CustomCountries["LU"] = "Luxembourg";
    CustomCountries["MX"] = "Mexico";
    CustomCountries["NL"] = "Netherlands";
    CustomCountries["NZ"] = "New Zealand";
    CustomCountries["NO"] = "Norway";
    CustomCountries["PL"] = "Poland";
    CustomCountries["PT"] = "Portugal";
    CustomCountries["SK"] = "Slovakia";
    CustomCountries["SI"] = "Slovenia";
    CustomCountries["ES"] = "Spain";
    CustomCountries["SE"] = "Sweden";
    CustomCountries["CH"] = "Switzerland";
    CustomCountries["TR"] = "Turkey";
    CustomCountries["GB"] = "United Kingdom";
    CustomCountries["US"] = "United States";
})(CustomCountries || (CustomCountries = {}));
export var LanguageList;
(function (LanguageList) {
    LanguageList["EN"] = "en";
    LanguageList["LT"] = "lt";
    LanguageList["LV"] = "lv";
    LanguageList["NL"] = "nl";
    LanguageList["RU"] = "ru";
    LanguageList["PL"] = "pl";
    LanguageList["FR"] = "fr";
    LanguageList["BEnl"] = "BEnl";
})(LanguageList || (LanguageList = {}));
export var BusinessCountries;
(function (BusinessCountries) {
    BusinessCountries["BE"] = "Belgium";
    BusinessCountries["FR"] = "France";
    BusinessCountries["DE"] = "Germany";
    BusinessCountries["IT"] = "Italy";
    BusinessCountries["LV"] = "Latvia";
    BusinessCountries["LT"] = "Lithuania";
    BusinessCountries["NL"] = "Netherlands";
    BusinessCountries["ES"] = "Spain";
    BusinessCountries["PL"] = "Poland";
})(BusinessCountries || (BusinessCountries = {}));
