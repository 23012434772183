var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { borderRadius, color, fontFamily, fontSize, fontStyle, fontWeight, layout, letterSpacing, lineHeight, size, space, textAlign, textStyle, system, } from 'styled-system';
export default styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  color: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  display: inline-block;\n  color: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, space, fontSize, fontStyle, size, color, layout, textStyle, letterSpacing, fontFamily, fontWeight, borderRadius, lineHeight, textAlign, system({ textTransform: true }));
var templateObject_1;
