var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@rebass/grid';
import { useReactiveVar } from '@apollo/client';
import LogoIcon from 'app/atoms/icons/factris-logo.svg';
import { Space } from 'app/styles';
import { Link } from 'app/atoms/link';
import { path, getPathWithDealIdQueryString } from 'app/navigation';
import { HamburgerMenu } from 'app/atoms/hamburger-menu';
import useToggle from 'app/hooks/use-toggle';
import useLockBodyScroll from 'app/hooks/use-lock-body-scroll';
import { useClickOutside } from 'app/hooks/use-click-outside';
import i18n from 'app/translations/i18n';
import { useLogout } from 'app/auth/auth-providers';
import { LanguageSelector } from './atoms/language-selector';
import { MobileHeaderLink } from './atoms/mobile-header-link';
import { isAuthenticatedVar } from 'app/apollo/cache';
var MobileHeaderContent = function () {
    var t = useTranslation().t;
    var logout = useLogout().logout;
    var startPage = getPathWithDealIdQueryString(path.startPage);
    var _a = useState(i18n.language), lng = _a[0], setLanguage = _a[1];
    var _b = useToggle(false), isMenuOpen = _b[0], toggleMenu = _b[1];
    var closeMenu = function () { return toggleMenu(false); };
    var ref = useClickOutside(closeMenu);
    useLockBodyScroll(isMenuOpen);
    var isAuthenticated = useReactiveVar(isAuthenticatedVar);
    useEffect(function () {
        setLanguage(i18n.language);
    }, [i18n.language]);
    var clearStore = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, logout()];
                case 1:
                    _a.sent();
                    toggleMenu(false);
                    return [2];
            }
        });
    }); };
    var handleToggleMenu = function () {
        toggleMenu();
    };
    return (React.createElement(Flex, { ref: ref, width: 1, justifyContent: "space-between", alignItems: "center" },
        React.createElement(Link, { to: "https://www.factris.com/" + lng + "/", externalLink: true },
            React.createElement(LogoIcon, { width: "165px", height: "30px", title: "https://www.factris.com/" + lng + "/" })),
        React.createElement(Flex, { justifyContent: "space-between", alignItems: "center", height: "80px" },
            React.createElement(Box, { mx: Space.S16 },
                React.createElement(LanguageSelector, null)),
            React.createElement(HamburgerMenu, { toggleMenu: handleToggleMenu, isMenuOpen: isMenuOpen }),
            React.createElement(MenuContent, { isMenuOpen: isMenuOpen }, isAuthenticated
                ? (React.createElement(Fragment, null,
                    React.createElement(MobileHeaderLink, { dataTest: "header-start", path: startPage, linkText: t('header.start'), onClick: closeMenu }),
                    React.createElement(MobileHeaderLink, { dataTest: "header-logout", path: path.loginPage, linkText: t('header.logout'), onClick: clearStore })))
                : (React.createElement(MobileHeaderLink, { dataTest: "header-login", path: path.loginPage, linkText: t('header.login'), onClick: closeMenu }))))));
};
var MenuContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 100;\n  height: 100vh;\n  width: 100%;\n  max-width: 300px;\n  background-color: ", ";\n  top: 0;\n  right: 0;\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  transform: ", ";\n  transition: transform .8s;\n  transition-timing-function: cubic-bezier(.7,0,.3,1);\n"], ["\n  position: absolute;\n  z-index: 100;\n  height: 100vh;\n  width: 100%;\n  max-width: 300px;\n  background-color: ", ";\n  top: 0;\n  right: 0;\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  transform: ", ";\n  transition: transform .8s;\n  transition-timing-function: cubic-bezier(.7,0,.3,1);\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.space.S80 + " " + theme.space.S32;
}, function (_a) {
    var isMenuOpen = _a.isMenuOpen;
    return isMenuOpen ? 'translateX(0)' : 'translateX(100%)';
});
export default MobileHeaderContent;
var templateObject_1;
