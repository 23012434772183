var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LinkTag from './link-tag';
var Link = function (_a) {
    var _b;
    var to = _a.to, newTab = _a.newTab, externalLink = _a.externalLink, children = _a.children, TextComponent = _a.as, textProps = _a.textProps, underline = _a.underline, dataTest = _a.dataTest;
    return (React.createElement(TextComponent, __assign({ as: externalLink ? 'a' : RouterLink, target: newTab ? '_blank' : undefined, underline: underline ? underline : undefined, "data-test": dataTest }, (_b = {},
        _b[externalLink ? 'href' : 'to'] = to,
        _b), textProps), children));
};
Link.defaultProps = {
    as: LinkTag,
    underline: false
};
export default memo(Link);
